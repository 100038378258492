import '../css/Footer.css'

function Footer({children, style, className}) {
    return <section id="footer" className={className} style={style}>
        {children}
        <div className="footer-copyright">
            <span>Copyright 2024 | All rights reserved Keel Digital Solutions</span>
            <div className="links">
                <a href="https://www.keelmind.com/terms-of-use" aria-label='Terms of Use'>
                    Terms of Use
                </a>
                <span>|</span>
                <a href="https://www.keelmind.com/privacy-policy" aria-label='Privacy Policy'>
                    Privacy Policy
                </a>
            </div>
        </div>
    </section>;
}

export default Footer;