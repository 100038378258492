import "../css/PeerSupportProgram.css";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeaderLogo from "../components/HeaderLogo";
import OrgLink from "../components/OrgLink";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import orgData from "../orgData";
import ProgramCard from "../components/ProgramCard";

function PeerSupportProgram() {
    const params = useParams();
    return orgData[params.org]?.orgCodes ? (
        <HasOrgCodesPage org={params.org} />
    ) : (
        <NoOrgCodesPage />
    );
}

function HasOrgCodesPage({ org }) {
    return (
        <div
            id="Has-Codes-Peer-Support-Program-page"
            className="basic peer-support-program-page"
        >
            <Header dark={true} />
            <div className="content" role="main">
                <h1 className="heading">Join the Right Program</h1>
                <div className="program-cards-container">
                    <ProgramCard
                        bigShadow={true}
                        orangeButton={true}
                        title={"Become a Peer Supporter"}
                        checks={[]}
                        buttonText={"Join as a Peer Supporter"}
                        url={`https://app.ca.geta-head.com/app/gah/#/register/orgcode/${orgData[org].orgCodes.peerSupport}`}
                    >
                        <strong>Provide</strong> support for your peers through
                        the Keel Mind platform
                    </ProgramCard>
                    <ProgramCard
                        orangeButton={true}
                        title={"Become a Peer"}
                        checks={[]}
                        buttonText={"Join as a Peer"}
                        url={`https://app.ca.geta-head.com/app/gah/#/register/orgcode/${orgData[org].orgCodes.peer}`}
                    >
                        <strong>Receive</strong> support from your peers through
                        the Keel Mind platform
                    </ProgramCard>
                </div>
            </div>
            <Footer />
        </div>
    );
}

function NoOrgCodesPage() {
    const [formState, setFormState] = useState("input");

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const email = encodeURIComponent(event.target.email.value);
        fetch(
            `https://docs.google.com/forms/d/e/1FAIpQLSdc0JRo-v5dbm2nyO3lP5PwfbGO9bNnG5Zs4EHkoo-nsynDQQ/formResponse?entry.802496195=${email}`,
            {
                method: "GET",
                mode: "no-cors",
            }
        )
            .then(() => {
                setFormState("success");
            })
            .catch(() => {
                setFormState("error");
            });
    };

    useEffect(() => {
        document.title = "Peer Support Program | Keel Mind";
    });
    return (
        <div
            id="No-Codes-Peer-Support-Program-page"
            className="basic peer-support-program-page"
        >
            <div
                className="hero"
                style={{
                    backgroundImage: `url(/support-hero.jpg)`,
                }}
            >
                <Header justHamburgerButton={true} />
            </div>
            <div className="content" role="main">
                <HeaderLogo dark={true} />

                <h1 className="heading">Need someone to talk to?</h1>
                <div className="description">
                    <strong>We'll be ready for you soon!</strong> Please drop
                    your email address in the field below, you will receive
                    instructions on how to proceed when the program is launched!
                </div>
                <div className="form-container">
                    <form
                        onSubmit={handleFormSubmit}
                        aria-label="Waitlist sign up form"
                    >
                        {{
                            error: (
                                <div
                                    className="form-error"
                                    aria-label="Error while submitting the form"
                                >
                                    Oops! Something went wrong while submitting
                                    the form.
                                </div>
                            ),
                            success: (
                                <div
                                    className="form-success"
                                    aria-label="Form successfully submitted"
                                >
                                    Thank you! Your submission has been
                                    received!
                                </div>
                            ),
                        }[formState] || (
                            <>
                                <label id="email-label">
                                    <strong>Enter your email</strong>
                                    <input
                                        type="email"
                                        required
                                        name="email"
                                        placeholder="example@mail.com..."
                                        maxLength={254}
                                        aria-label="Email address"
                                    />
                                </label>
                                <input
                                    type="submit"
                                    value="Keep me notified"
                                    aria-label="Submit form"
                                />
                            </>
                        )}
                    </form>
                    <div className="other-email">
                        <span>
                            *Don't want to use your personal email? There's
                            options{" "}
                            <OrgLink to="/email-suggestions">here</OrgLink>.
                        </span>
                    </div>
                    <div className="separator-container">
                        <div className="separator"></div>
                        <span>or</span>
                        <div className="separator"></div>
                    </div>
                    <OrgLink to="/crisis-support" className="assistance-button">
                        I Need Assistance Now!
                    </OrgLink>
                </div>
                <Footer
                    style={{
                        padding: 0,
                    }}
                    className={"stack"}
                />
            </div>
        </div>
    );
}

export default PeerSupportProgram;
