import { useEffect } from "react";
import "../css/ReadinessChecks.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CTAButton from "../components/CTAButton";
import { useParams } from "react-router-dom";

function ReadinessChecks() {
    useEffect(() => {
        document.title = "Readiness Checks | Keel Mind";
    }, []);
    const params = useParams();
    return (
        <div id="Readiness-Checks-page" className="basic card-page">
            <Header dark={true} />
            <main>
                <h1 className="heading">Preparing your Readiness Check</h1>
                <div className="description">
                    Please check out the features and descriptions below.
                </div>
                <div className="card-page-card">
                    <div className="card-info">
                        <img src="/fun_&_easy.png" alt="" />
                        <h2 className="heading">Fun & Easy</h2>
                        <div className="description">
                            This engaging game environment offers an enjoyable
                            and seamless way to pinpoint key indicators of
                            mental wellness and performance, including mental
                            strain, burnout, stress, distress, fatigue, and
                            confidence.
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="card-info">
                        <img src="/share_results.png" alt="" />
                        <h2 className="heading">Share Results</h2>
                        <div className="description">
                            Sharing your results could make a significant impact
                            in normalizing conversations about mental wellness.
                            The decision to share is entirely up to you; there's
                            no pressure.
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="card-info">
                        <img src="/confidential.png" alt="" />
                        <h2 className="heading">Confidential</h2>
                        <div className="description">
                            Protecting what you share and any related sensitive
                            information is important to us, which is why we are
                            fully PHIPA and PIPEDA compliant.
                        </div>
                    </div>
                    <div className="separator"></div>
                    <div className="card-info">
                        <img src="/ai_driven.png" alt="" />
                        <h2 className="heading">AI Driven</h2>
                        <div className="description">
                            By leveraging cutting-edge advancements in machine
                            learning and neuropsychology, we deliver AI-powered
                            quantifiable scores and other real-time mental
                            health insights.
                        </div>
                    </div>
                </div>
                <CTAButton
                    href={
                        !(
                            window.location.hostname.includes("dev") ||
                            window.location.hostname.includes("localhost")
                        )
                            ? `https://assets.cdn.geta-head.com/kmta/prod/index.html?redirect=${
                                  window.location.origin +
                                  (params.org ? `/${params.org}` : "") +
                                  `/peer-support-program-choice`
                              }`
                            : `https://assets.cdn.geta-head.com/kmta/dev/index.html?redirect=${
                                  window.location.origin +
                                  (params.org ? `/${params.org}` : "") +
                                  `/peer-support-program-choice`
                              }`
                    }
                    text={"Start a Readiness Check"}
                    target="_blank"
                />
            </main>
            <Footer />
        </div>
    );
}

export default ReadinessChecks;
