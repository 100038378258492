import OrgLink from "./OrgLink";

function HeaderLogo({ dark }) {
    return (
        <OrgLink to={"/"} className="header-logo-a" label={"Link to First Responders Peer Support | Keel Mind"}>
            <img
                className="header-logo"
                draggable="false"
                src={dark ? "/keel-mind-logo-dark.png" : "/keel-mind-logo.png"}
                alt=""
            />
        </OrgLink>
    );
}

export default HeaderLogo;
