import "../css/CTAButton.css";
import OrgLink from "./OrgLink";
function CTAButton({ text = "Let's Get Started", alt = false, outline=false, style={}, href="/peer-support-program-choice", target="" }) {
    return (
        <OrgLink to={href}
            className={"CTA-button " + (alt ? "alt " : "") + (outline ? "outline " : "")} style={
                style
            }
            target={target}
        >
            {text}
        </OrgLink>
    );
}

export default CTAButton;
