export function orgPageSetup(pageRef, group) {
    function processPageForGroup() {
        processOrgCode();

        // if (Object.keys(orgData).includes(group)) {
        //     addOrgStyle(group);
        //     pageRef.current.classList.remove("default-org-page");
        //     pageRef.current.classList.add(group + "-org-page");
        // }

        // updateBackground(group);
        // showContainer(group);
        // showIcon(group);
    }

    function processOrgCode() {
        const orgCode = new URLSearchParams(window.location.search).get(
            "orgcode"
        );
        //dev
        /*
            const registerDomain = "https://v2.dev.geta-head.com";
            const registerPath = "/app/gah/#/register/orgcode";
            const defaultOrgCode = 'PATn';
            */

        //prod

        const registerDomain = "https://app.ca.geta-head.com";
        const registerPath = "/app/gah/#/register/orgcode";
        const defaultOrgCode = "qlYl";

        //staging
        /*
            const registerDomain = "https://v2.staging.geta-head.com";
            const registerPath = "/app/gah/#/register/orgcode";
            const defaultOrgCode = 'Jjph';
            */

        let registerURL = `${registerDomain}${registerPath}/${
            orgCode || defaultOrgCode
        }`;

        localStorage.setItem("registerurl", registerURL);
    }

    // function addOrgStyle(orgName, ref) {
    //     const rule = `
    //             .${orgName}-org-page .org-element:not(.${orgName}-org-element) {
    //                 display: none !important;
    //             }
    //         `;
    //     const styleElement = document.createElement("style");
    //     document.head.appendChild(styleElement);
    //     const sheet = styleElement.sheet;
    //     sheet.insertRule(rule);
    // }

    window.addEventListener("load", function () {
        //console.log('now in the gah program connect load');
        processPageForGroup();
    });
}
