import "../css/CarouselDot.css";

export const CarouselDot = ({ onClick, scrollToElement, ...rest }) => {
    const {
        // onMove,
        index,
        active,
        // carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.

    return (
        <button
            aria-label={
                "Go to slide " +
                (index + 1) +
                " button: currently " +
                (active ? "active" : "inactive")
            }
            aria-current={active ? true : null}
            className={"carousel-dot " + (active ? "active" : "inactive")}
            onClick={() => onClick()}
            onFocus={scrollToElement}
        ></button>
    );
};
