import { useEffect } from "react";
import "../css/EmailSuggestions.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import EmailCard from "../components/EmailCard";

const emailProviders = [
    {
        name: "Tutanota",
        description: "Tutanota is a free encrypted email provider",
        img: "/email-icons/tutanota.png",
        href: "https://tuta.com/",
    },
    {
        name: "ProtonMail",
        description: "ProtonMail is a free encrypted email provider",
        img: "/email-icons/protonmail.png",
        href: "https://proton.me/",
    },
    {
        name: "Hushmail",
        description: "Hushmail is a free encrypted email provider",
        img: "/email-icons/hushmail.png",
        href: "https://hushmail.com/",
    },
    {
        name: "Mailfence",
        description: "Mailfence is a free encrypted email provider",
        img: "/email-icons/mailfence.png",
        href: "https://mailfence.com/",
    },
];

function EmailSuggestions() {
    useEffect(() => {
        document.title = "Email Suggestions | Keel Mind";
    });
    return (
        <div id="Email-Suggestions-page" className="basic">
            <Header dark={true} />
            <div className="email-suggestions-body" role="main">
                <div className="email-suggestions-text">
                    <h1 className="heading">
                        Don't want to use your personal email?
                    </h1>
                    <div className="description">
                        We respect your privacy and would like to present you
                        with some options where you can easily go to create
                        another anonymous email address.
                    </div>
                    <strong>
                        Once you've set one up, come back and use that address.
                    </strong>
                </div>
                <div className="email-cards-container">
                    {emailProviders.map((e) => (
                        <EmailCard key={e.name} {...e} />
                    ))}
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default EmailSuggestions;
