function ImageSection(props) {
    return (
        <section id={props.id} className={props.className}
            style={{
                backgroundImage: `url(${props.bgImgUrl})`,
                backgroundPosition: "top center",
                backgroundSize: "cover",
            }}
        >
            {props.children}
        </section>
    );
}

export default ImageSection;
