import "../css/EmailCard.css";

function EmailCard({ img, name, description, href }) {
    return (
        <a
            href={href}
            className="email-card"
            aria-label={name}
            aria-describedby={name + "-description"}
        >
            <div className="img-container">
                <img src={img} alt={name + " icon"} />
            </div>
            <div className="title">{name}</div>
            <div id={name + "-description"} className="description">
                {description}
            </div>
        </a>
    );
}

export default EmailCard;
