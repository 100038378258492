import orgData from "../orgData";

function OrgLogo({ org, useLogoSize }) {
    return (
        <img
            className={"org-logo"}
            src={
                "https://assets.cdn.geta-head.com/keelreels/" +
                org +
                "/img/" +
                org +
                "_logo.png"
            }
            alt={orgData[org].logoAlt || orgData[org].name + " logo"}
            style={
                (orgData[org].logoSize &&
                    useLogoSize && {
                        width: orgData[org].logoSize,
                        height: orgData[org].logoSize,
                    }) || {
                    width: 150,
                    height: 150,
                }
            }
        />
    );
}

export default OrgLogo;
