import Footer from "../components/Footer";
import Header from "../components/Header";
import "../css/AllOrgs.css";
import orgData from "../orgData";
import { Link } from "react-router-dom";

function AllOrgs() {
    return (
        <div id="all-orgs" className="basic">
            <Header dark={true} />
            <div id="all-orgs-content">
                <h1>All Peer Support Organizations</h1>
                <div id="all-orgs-list">
                    {Object.entries(orgData)
                        .filter(([key, value]) => !value.ignore)
                        .map(([key, value]) => {
                            return (
                                <Link key={key} to={"/" + key}>
                                    {value.name}
                                </Link>
                            );
                        })}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AllOrgs;
