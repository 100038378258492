import "../css/Hamburger.css";
function HamburgerButton(props) {
    function handleClick() {
        if (!props.ghost) {
            props.setMenuState((prev) => !prev);
        }
    }

    return (
        <button
            onClick={handleClick}
            className={`hamburger-button ${props.ghost ? "ghost" : ""} ${
                props.dark ? "dark" : ""
            }`}
            aria-label="Open navigation menu"
        >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </button>
    );
}

export default HamburgerButton;
