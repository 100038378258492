function Card(props) {
    return (
        <div className="card">
            <img src={props.img} alt={""} className="card-img" />
            <div className="card-content">
                <h3 className="title">{props.title}</h3>
                <div className="description">{props.description}</div>
            </div>
        </div>
    );
}

export default Card;
