import "../css/Carousel.css";

import Carousel from "react-multi-carousel";
import { CarouselDot } from "./CarouselDot";
import { useEffect, useRef } from "react";

function CarouselWrapper(props) {
    const carouselRef = useRef(null);
    const leftRef = useRef(null);
    const rightRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            disableLeftIfShould();
            disableRightIfShould();
            disableFocusIfShould();
        }, 1);
    }, []);

    function disableLeftIfShould() {
        const state = carouselRef.current.state;
        const should = state.currentSlide === 0;

        if (should) {
            leftRef.current.setAttribute("disabled", true);
        } else {
            leftRef.current.removeAttribute("disabled");
        }
    }

    function disableRightIfShould() {
        const state = carouselRef.current.state;
        const totalSlides = Math.ceil(state.totalItems / state.slidesToShow);
        const should = state.currentSlide === totalSlides - 1;

        if (should) {
            rightRef.current.setAttribute("disabled", true);
        } else {
            rightRef.current.removeAttribute("disabled");
        }
    }

    function disableFocusIfShould() {
        const list = carouselRef.current.listRef.current;

        list.querySelectorAll(
            "li[aria-hidden='true']>.reel *:is(a, button)"
        ).forEach((e) => {
            e.setAttribute("tabindex", "-1");
        });
        list.querySelectorAll(
            "li[aria-hidden='false']>.reel *:is(a, button)"
        ).forEach((e) => {
            e.removeAttribute("tabIndex");
        });
    }

    function handleLeft() {
        carouselRef.current.previous();
    }

    function handleRight() {
        carouselRef.current.next();
    }

    function focusRevealedContent() {
        carouselRef.current.listRef.current
            .querySelector("li[aria-hidden='false'] *:is(a, button)")
            .focus();
    }

    return (
        <div className="carousel-wrapper">
            <div>
                <button
                    ref={leftRef}
                    className="carousel-arrow carousel-left-arrow"
                    style={{
                        backgroundImage: 'url("/chevron-left.svg")',
                    }}
                    onClick={handleLeft}
                    onFocus={props.scrollToElement}
                ></button>
                <div className="carousel-arrow-spacer"></div>
            </div>
            <Carousel
                ref={carouselRef}
                swipeable={true}
                showDots={true}
                customDot={
                    <CarouselDot scrollToElement={props.scrollToElement} />
                }
                renderDotsOutside={true}
                arrows={false}
                afterChange={() => {
                    disableLeftIfShould();
                    disableRightIfShould();
                    disableFocusIfShould();
                    focusRevealedContent();
                }}
                responsive={{
                    desktop: {
                        breakpoint: { max: 30000, min: 1024 },
                        items: Math.min(3, props.children.length),
                        slidesToSlide: 3, // optional, default to 1.
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 850 },
                        items: Math.min(2, props.children.length),
                        slidesToSlide: 2, // optional, default to 1.
                    },
                    mobile: {
                        breakpoint: { max: 850, min: 0 },
                        items: 1,
                        slidesToSlide: 1, // optional, default to 1.
                    },
                }}
                containerClass="carousel-container"
            >
                {props.children}
            </Carousel>
            <div>
                <button
                    ref={rightRef}
                    className="carousel-arrow carousel-right-arrow"
                    style={{
                        backgroundImage: 'url("/chevron-right.svg")',
                    }}
                    onClick={handleRight}
                    onFocus={props.scrollToElement}
                ></button>
                <div className="carousel-arrow-spacer"></div>
            </div>
        </div>
    );
}

export default CarouselWrapper;
