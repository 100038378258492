import { useRef, useState } from "react";
import "../css/Reels.css";

function Reel({ name, subtitle, quote, reelsOrg, scrollToElement }) {
    const [playState, setPlayState] = useState(false);
    const audioRef = useRef(null);

    function handleClick() {
        setPlayState((s) => {
            if (s) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }

            return !s;
        });
    }

    return (
        <div className="reel">
            <div className="reel-circle">
                <img
                    draggable={false}
                    className="reel-portrait"
                    src={`https://assets.cdn.geta-head.com/keelreels/${reelsOrg}/img/${name
                        .split(" ")
                        .join("_")}_headshot.jpg`}
                    alt={name + " headshot"}
                    loading="lazy"
                />
                <button
                    className="reel-button"
                    onClick={handleClick}
                    onFocus={scrollToElement}
                    aria-label={
                        (playState ? "Pause" : "Play") +
                        " " +
                        name +
                        " interview audio"
                    }
                >
                    <img
                        draggable={false}
                        src={playState ? "/pause-icon.png" : "/play-icon.png"}
                        alt={(playState ? "play" : "pause") + " icon"}
                    />
                </button>
            </div>
            <div className="reel-content">
                <div className="name">{name}</div>
                <div className="subtitle">{subtitle}</div>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/transcripts/${reelsOrg}/${name
                        .split(" ")
                        .join("_")}_transcript.txt`}
                    className="text-transcript-link"
                    aria-label={
                        "View transcript (.txt file) for " +
                        name +
                        " interview.`"
                    }
                >
                    View transcript (.txt file)
                </a>
                {/* <div className="quote">{quote}</div> */}
            </div>
            <audio
                src={`https://assets.cdn.geta-head.com/keelreels/${reelsOrg}/m4a/${name
                    .split(" ")
                    .join("_")}_interview.m4a`}
                ref={audioRef}
                aria-label={name + " interview audio"}
            ></audio>
        </div>
    );
}

export default Reel;
