import "../css/BuildingTrust.css";
import Header from "../components/Header";
import FeatureCard from "../components/FeatureCard";
import { useEffect } from "react";
import Footer from "../components/Footer";
import OrgLink from "../components/OrgLink";
import { useParams } from "react-router-dom";

function BuildingTrust() {
    const params = useParams();
    useEffect(() => {
        document.title = "Building Trust | Keel Mind";
    });

    return (
        <div id="Building-Trust-page" className="basic">
            <title>Bulding Trust</title>
            <Header dark={true} />
            <main>
                <div className="heading-section">
                    <div className="subtitle">
                        With sensitivity and preparedness in mind.
                    </div>
                    <h1 className="title">
                        Building Trust & Assurity in Steps.
                    </h1>
                    <div className="description">
                        The Keel Mind Peer Support Program is committed to
                        ensure your comfortability and fully respect your
                        privacy.
                    </div>
                </div>
                <div className="feature-card-container">
                    <div className="feature-card-row">
                        <FeatureCard
                            title={"Investigating & Curiosity"}
                            tag={"Curious & Anonymous"}
                        >
                            Visiting the Keel Mind Peer Support website and
                            learning the features and goals of the program.
                            Browse content and listen to stories of peers and
                            their experiences with the program.
                        </FeatureCard>
                        <FeatureCard
                            title={"Wellness Check-Ins"}
                            tag={"Curious & Anonymous"}
                        >
                            Participate in our Keel Mind Wellness Check-ins.
                            It's fun, engaging, and completely anonymous.
                        </FeatureCard>
                        <FeatureCard
                            title={"Getting Comfortable"}
                            tag={"Getting Introduced"}
                        >
                            You're comfortable with the goals of the overall
                            mission of the Keel Mind Peer Support program and
                            you have completed multiple Wellness Check-ins.
                            You'd like to proceed and create an account.
                        </FeatureCard>
                        <FeatureCard
                            title={"Initial Introduction"}
                            tag={"Getting Introduced"}
                            className={"show-when-2-cols"}
                        >
                            Creating an account (
                            <OrgLink to="/email-suggestions">
                                anonymous options
                            </OrgLink>
                            ), accessing and browsing content in Wellness Hub,
                            and Wellness Check-ins.
                        </FeatureCard>
                    </div>

                    <div className="feature-card-row">
                        <FeatureCard
                            title={"Initial Introduction"}
                            tag={"Getting Introduced"}
                            className={"hide-when-2-cols"}
                        >
                            Creating an account (
                            <OrgLink to="/email-suggestions">
                                anonymous options
                            </OrgLink>
                            ), accessing and browsing content in Wellness Hub,
                            and Wellness Check-ins.
                        </FeatureCard>
                        <FeatureCard
                            title={"Connecting with Peers"}
                            tag={"Established Relationship"}
                        >
                            You would like to speak with someone. You're
                            comfortable and are ready to make the move toward
                            positive outcomes. Let's get you connected with one
                            of your peers, please{" "}
                            <OrgLink
                                to={
                                    params.org === "ledrexecutive"
                                        ? "/peer-support-program-choice"
                                        : "/peer-support-program"
                                }
                            >
                                login to access
                            </OrgLink>{" "}
                            your list of peer supporters.
                        </FeatureCard>
                        <FeatureCard
                            title={"Private Live Chat & Analytics"}
                            tag={"Established Relationship"}
                        >
                            You've established a relationship with one of your
                            peers and are ready to engage in private one on one
                            live chat. Analytics and sharing of data are
                            completely under your control and discretion.
                        </FeatureCard>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default BuildingTrust;
