import "../css/FeatureCard.css";

function FeatureCard({ tag, title, children, className }) {
    function tagClassName(tagText) {
        switch (tagText) {
            case "Curious & Anonymous":
                return "orange";
            case "Getting Introduced":
                return "blue";
            case "Established Relationship":
            default:
                return "green";
        }
    }

    return (
        <div className={"feature-card " + className}>
            <div className={`tag ${tagClassName(tag)}`}>{tag}</div>
            <h2 className="heading">{title}</h2>
            <div className="description">{children}</div>
        </div>
    );
}

export default FeatureCard;
